import { useNavigate } from 'react-router-dom'
import { ButtonCommon, Wrapper } from '../Common'

type props = {
  children?: React.ReactNode
}

export const PrivacyPolicy = ({ children }: props) => {
  const navigate = useNavigate()

  return (
    <Wrapper className="relative min-h-[calc(100vh-74px)] space-y-5 bg-ma-80 py-10 text-white">
      <div className="absolute inset-0 bg-white">
        <div className="absolute inset-x-4 bottom-24 top-4 overflow-auto bg-white text-black">
          <h4 className="font-semibold">Privacy Policy</h4>
          <p className="mb-4 mt-4">Last Revised on August 6th, 2024</p>
          <p className="mb-4 mt-4">
            Welcome to the Privacy Policy (“Privacy Policy”) provided by Heart-Tech Health, Inc. (“Heart-Tech”). This Privacy Policy describes how we collect, use, and disclose information about you
            related to your use of and access to our website (myadesso.health), content, applications, services, tools, and features, provided by Heart-Tech, (collectively, the “Services”). Please
            read this Privacy Policy carefully.
          </p>
          <p className="mb-4 mt-4">For purposes of this Privacy Policy, “you” and “your” means you as the user of the Services.</p>
          <p className="mb-4 mt-4">
            This Privacy Policy applies only to the information collected in connection with the Services. Please read this Privacy Policy carefully to understand our policies and practices regarding
            your information. If you do not agree with our policies and practices, your choice is not to use our Services. By accessing and using the Services, you acknowledge this Privacy Policy.
          </p>
          <p className="mb-4 mt-4">
            [If you are a physician and are using the Services in your professional capacity, you agree to be bound by the Business Associate Agreement set forth in [{' '}
            <a href="/BAA_Template_2024.docx" download="BAA_Template_2024.docx" className="cursor-pointer text-black">
              BAA.doc
            </a>{' '}
            ] (the “BAA”). Please refer to the BAA to understand how we collect, use, and disclose protected health information you may share with us as a physician, and therefore as a covered entity,
            as such term is defined under the Health Insurance Portability and Accountability Act of 1996, as amended by the Health Information Technology for Economic and Clinical Health Act of 2009,
            and regulations promulgated thereunder (collectively, “HIPAA”). If you are a physician and are using the Services in your professional capacity and you do not agree to the BAA, please do
            not use the Services or share any health-related information of your patients with us.]
          </p>
          <h4 className="font-semibold">1. WHAT INFORMATION WE COLLECT</h4>
          <p className="mb-4 mt-4"> Information We Collect About You through the Services:</p>
          <p className="mb-4 mt-4">When you use the Services, we may collect information about you, including:</p>
          <ul className="mb-4 mt-4 list-disc pl-8">
            <li className="mb-2 mt-2">Registration details you provide when you create an account, such as your name, e-mail address, telephone number, birthdate, username, and password.</li>
            <li className="mb-2 mt-2">
              Health-related and other personal information you provide when you use the Services, including information related to your health condition, as well as other information you provide when
              you access or use the Services and each time you interact with the Services or otherwise with us, for example, when you update your personal information in your app, communicate with us
              by telephone or e-mail, and download updates.{' '}
            </li>
            <li className="mb-2 mt-2">
              When using our mobile applications users may grant Heart-Tech access to activity data such as: heart rate, exercise activity, steps taken, blood pressure, sleep activity and weight. This
              data is used to show progress within our programs and to monitor compliance.
            </li>
            <li className="mb-2 mt-2">
              Information we receive about you from third parties (e.g., if you access the Services through a third-party platform or log-in), we may also collect your e-mail address and other
              information associated with that third party, and any information you have made public in connection with that service or which the third party shares with partner services). See also
              Section 6 (“Third Party Websites and Links”) below.
            </li>
          </ul>
          <h4 className="font-semibold">Information that We Collect Automatically:</h4>
          <p className="mb-4 mt-4">
            [We also collect certain information automatically from you. We and the third parties we work with use cookies and similar tracking technologies to track the activity on our Services and
            to collect information about your use of the Services, such as your IP address, browser type, browser version, pages viewed, time spent on pages, links clicked, location and conversion
            information. This information may be used by us and others to, among other things, analyze and track data, determine the popularity of certain content, provide customer support,
            troubleshoot issues with and improve the operation of the Services, and better understand your online activity. Cookies are files with small amounts of data that may include an anonymous
            unique identifier. They are sent to your browser from a website and stored on your device. If you do not want a cookie placed on your hard drive or mobile device, you may be able to turn
            that feature off on your computer or mobile device. Please consult your Internet browser’s documentation for information on how to do this. However, if you decide not to accept cookies
            from us, the Services may not function properly.]
          </p>
          <p className="mb-4 mt-4">
            [We also use Google Analytics, which is a web analytics tool that helps us understand how users engage with the Services. Like many services, Google Analytics uses first-party cookies to
            track user interactions, as in our case, where they are used to collect information about how users use our site. This information is used to compile reports and to help us improve the
            Services. The reports disclose website trends without identifying individual visitors. You can opt out of Google Analytics without affecting how you visit our site – for more information
            on opting out of being tracked by Google Analytics across all websites you use, visit this Google page: https://tools.google.com/dlpage/gaoptout.]
          </p>
          <h4 className="font-semibold">ONLINE TRACKING</h4>
          <p className="mb-4 mt-4">
            “Do Not Track” is a privacy preference that users can set in some web browsers, allowing users to opt out of tracking by websites and online services. Like many websites, our website is
            not designed to respond to such signals, and we do not use or disclose your information in any way that would legally require us to recognize opt-out preference signals. To learn more
            about “do not track” signals, you can visit http://www.allaboutdnt.com/.
          </p>
          <h4 className="font-semibold">2. HOW WE USE YOUR INFORMATION</h4>
          <p className="mb-4 mt-4">The ways in which we may use your information include the following:</p>
          <ul className="mb-4 mt-4 list-disc pl-8">
            <li className="mb-2 mt-2">To provide you with the Services and other services you request.</li>
            <li className="mb-2 mt-2">To administer your account.</li>
            <li className="mb-2 mt-2">To communicate with you about your account or transactions with us and send you details or updates about features of the Services or changes to our policies.</li>
            <li className="mb-2 mt-2">
              To create anonymized and aggregated data sets that may be used for a variety of functions, including research, internal analysis, analytics, and other functions.
            </li>
            <li className="mb-2 mt-2">To personalize content and experiences.</li>
            <li className="mb-2 mt-2">To detect, investigate and prevent activities that may violate our policies or be illegal.</li>
            <li className="mb-2 mt-2">To optimize or improve the content, services, and features of the Services.</li>
            <li className="mb-2 mt-2">
              To personalize and improve the Services and user experiences, to increase the functionality and user friendliness of the Services, and to deliver content or features that match user
              profiles or interests.
            </li>
            <li className="mb-2 mt-2">To monitor and analyze the Services usage and trends and otherwise measure the effectiveness of the services.</li>
            <li className="mb-2 mt-2">To send you offers and promotions for our other products and services.</li>
            <li className="mb-2 mt-2">
              With your opt-in permission, we may (i) provide you with news and updates about the Company; or (ii) send you surveys to collect feedback about your experience with the Company, in order
              to improve the Services. You are permitted to opt out of any such communications at any time.
            </li>
          </ul>
          <h4 className="font-semibold">3. HOW WE SHARE YOUR INFORMATION</h4>
          <p className="mb-4 mt-4">
            [If you are a physician and are using the Services in your professional capacity, we will only share the protected health information you disclose to us in accordance with the BAA.]
          </p>
          <p className="mb-4 mt-4">For all other users, we will not share your personal information outside Health-Tech except in the following limited circumstances which you consent to:</p>
          <ul className="mb-4 mt-4 list-disc pl-8">
            <li className="mb-2 mt-2">
              When you allow to us share your personal information with another company, such as directing us to share your personal information with third-party sites or platforms, including social
              networking sites. If you use third-party sites or platforms made available through the Services, those third parties will have access to your data to the extent that you have authorized
              their access. Please consult Section 6 (“Third Party Website and Links”) below and the third party’s privacy policy for additional information.
            </li>
            <li className="mb-2 mt-2">
              When you request our referral services to forward or share certain content with a friend, such as an e-mail inviting a friend to use the Services or sharing certain content from the
              Services with a friend.
            </li>
            <li className="mb-2 mt-2">
              When we contract or partner with a third party to provide services on our behalf, for example, with companies who provide us with certain administrative services, as well as other
              partners who assist us in carrying out, developing and maintaining the Services.
            </li>
            <li className="mb-2 mt-2">
              In connection with the sale of a business, including merger, acquisition, or sale of all or a material portion of its assets, change in corporate control, or insolvency or bankruptcy
              proceedings.
            </li>
            <li className="mb-2 mt-2">
              To enforce our Terms of Service or rules, to ensure the safety and security of our users and third parties, to protect our rights and property and the rights and property of our users
              and third parties, to comply with legal process, including judicial warrant, rule, order or subpoena or in other cases if we believe in good faith that disclosure is required by law or
              regulation.
            </li>
          </ul>
          <p className="mb-4 mt-4">In addition, we may share anonymized and aggregated data sets with third parties, alone or in combination with anonymized information of other users.</p>
          <h4 className="font-semibold">4. YOUR CONTROLS AND CHOICES</h4>
          <p className="mb-4 mt-4">
            You have the ability to exercise certain controls and choices regarding our collection, use and sharing of your information. These controls and choices include your right to:
          </p>
          <ul className="mb-4 mt-4 list-disc pl-8">
            <li className="mb-2 mt-2">Correct, update and delete your registration account.</li>
            <li className="mb-2 mt-2">Request access to the personal information we hold about you and that we amend or delete it.</li>
          </ul>
          <p className="mb-4 mt-4">
            You may exercise your controls and choices, or request access to your personal information, by modifying your profile or by contacting us at info@hearttech.health or following instructions
            provided in communications sent to you. Please be aware that, if you do not allow us to collect personal information from you, we may not be able to deliver certain services to you, and
            some of our Services may not be able to take account of your interests and preferences. If you have questions regarding the specific personal information about you that we process or
            retain, please contact us at info@hearttech.health.
          </p>
          <h4 className="font-semibold">5. THIRD PARTY WEBSITES AND LINKS</h4>
          <p className="mb-4 mt-4">
            Our Services may contain links to other online platforms operated by third parties. We do not control such other online platforms and are not responsible for their content, their privacy
            policies, or their use of your personal information. Our inclusion of such links does not, by itself, imply any endorsement of the content on such platforms or of their owners or operators
            except as disclosed on the Services.
          </p>
          <p className="mb-4 mt-4">
            Please keep in mind that personal information you post on public or semi-public venues, including personal information you share on third party social networking platforms (such as
            Facebook or Twitter) may also be viewable by other users of the Services and/or users of those third-party online platforms without limitation as to its use by us or by a third party. When
            you provide personal information to a third-party online platform, the personal information you provide may be separately collected by that third-party. The personal information the
            third-party online platform collects is subject to the third-party online platform’s privacy practices. Privacy choices you make on the third-party online platform will not apply to our
            use of the information we collect directly through our Services.
          </p>
          <p className="mb-4 mt-4">
            We expressly disclaim any and all liability for the actions of third parties, including but without limitation to actions relating to the use and/or disclosure of personal information by
            third parties.
          </p>
          <h4 className="font-semibold">6. CHILDREN’S PRIVACY</h4>
          <p className="mb-4 mt-4">
            Our Services are not directed to children who are under 13 years of age, and we do not seek or knowingly collect any personal information from them without obtaining consent from the
            child’s parent or guardian. To the extent you wish to enable your child to use the Services, you must create an account for the child in accordance with our Terms of Service and provide
            consent for us to collect such information from your child. If we become aware that we have unknowingly collected personal information from a child under 13 years of age without such
            consent, we will make commercially reasonable efforts to delete such information from our database. Please note, however, that we may provide services to a child without parental consent
            for the purposes of protecting the health or safety of that child. In such cases, we will use reasonable efforts to provide a parent with notice that the child has registered for our
            services.
          </p>
          <p className="mb-4 mt-4">If you are the parent or guardian of a child under 13 years of age who has provided us with personal information, you may contact us to request it be deleted.</p>
          <h4 className="font-semibold">7. SECURITY AND DATA RETENTION</h4>
          <p className="mb-4 mt-4">
            Please note that Information you send to us electronically may not be secure when it is transmitted to us. We recommend that you do not use unsecure channels to communicate sensitive or
            confidential personal information to us. Once the Information is on our servers, we encrypt it at rest and in transit. Access to sensitive and personal Information is restricted to the
            user and designated health providers. From time to time, we review our security procedures to consider appropriate new technology and methods. Please be aware though that, despite our best
            efforts, no security measures are perfect or impenetrable. We will retain your personal information for the length of time needed to fulfill the purposes outlined in this Privacy Policy
            unless a longer retention period is required, or for the establishment, exercise or defense of legal claims, or for legitimate businesses purposes, or as provided by law.
          </p>
          <h4 className="font-semibold">8. CALIFORNIA RIGHTS</h4>
          <p className="mb-4 mt-4">
            “Shine the Light”: We [do not share] personal information as defined by California’s “Shine the Light” law (Cal. Civ. Code § 1798.83) with third parties for their direct marketing
            purposes.
          </p>
          <h4 className="font-semibold">9. UPDATING THIS PRIVACY POLICY</h4>
          <p className="mb-4 mt-4">
            We may modify this Privacy Policy from time to time in which case we will update the “Last Revised” date at the top of this Privacy Policy. If we make changes that are material, we will
            use reasonable efforts to attempt to provide notice to you and, where required by applicable law, we will obtain your consent. Notice may be by e-mail to you at the last e-mail address you
            provided us, by posting notice of such changes on the Services, or by other means, consistent with applicable law. However, it is your responsibility to review the Privacy Policy from time
            to time to view any such changes. The updated Privacy Policy will be effective as of the time of posting, or such later date as may be specified in the updated Privacy Policy.
          </p>
          <h4 className="font-semibold">10. HOW TO CONTACT US</h4>
          <p className="mb-4 mt-4">Any questions or concerns regarding the use or disclosure of personal information should be directed to info@hearttech.health.</p>
          <p className="mb-4 mt-4">You may contact us regarding the Services at 26 E. 36th NY NY 1002 or by e-mail at info@hearttech.health.</p>
        </div>
        <div className="absolute bottom-4 left-0 right-0 text-center">{children ?? <ButtonCommon className="px-8 py-4" text="Go Home" onClick={() => navigate('/dashboard')} />}</div>
      </div>
    </Wrapper>
  )
}
